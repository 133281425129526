import { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// import HistoryButton from "../components/Buttons/HistoryButton";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loaders/Loader";

import styled from "@emotion/styled";
import SettingsAccordion from "../components/Accordions/SettingsAccordion.jsx";
import Button from "../components/Buttons/Button.jsx";
import { FormWrapper, InputWrapper, Label } from "../components/Forms/Form.jsx";
import {
  // ButtonWrapper,
  ColumnDiv,
  ColumsWrapper,
  ContentWrapper,
  Divider,
  PageWrapper,
  SaveButtonWrapper,
  StyledInput,
  StyledTextArea,
  TextDiv,
} from "../components/Forms/SettingsForm.jsx";
import QueriesLangSwitch from "../components/Switchs/QueriesLangSwitch.jsx";
 import RoundedSwitch from "../components/Switchs/RoundedSwitch.jsx";
import useGenAITiles from "../hooks/useGenAIParameters.js";
import { setChanges,setKeyValue } from "../features/changeSlice";

const regexFilterKey = /^([a-z-]*)$/;

export default function UiSettings() {
  const params = useParams();
  const [isNewTile, setIsNewTile] = useState();
  const [keyAlreadyExists, setKeyAlreadyExists] = useState();
  const [invalidKey, setInvalidKey] = useState();
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isStream, setStreamResponse] = useState(true);
  const [tileDescription, setTileDescription] = useState("");
  const changes = useSelector((state) => state.change.changes);
  

  const dispatch = useDispatch();

  const {
    tiles,
    currentTile,
    isLoading,
    handleFilterPropertyChange,
    fetchedParameters,
    setIsLoading,
    saveFilterChanges,
    createNewFilter,
  } = useGenAITiles();

  useEffect(() => {
    if (fetchedParameters.length > 0 && isNewTile) {
      setKeyAlreadyExists(
        fetchedParameters
          .map((filter) => filter.key)
          .indexOf(currentTile.key) !== -1
      );
    } else {
      setKeyAlreadyExists(false);
    }
  }, [fetchedParameters, tiles]);

  useEffect(() => {
    if (params.tile === "new") {
      setIsNewTile(true);
      setStreamResponse(true)
    }
  }, []);

  useEffect(() => {
    if (!currentTile) return;
    if (currentTile.key) {
      setInvalidKey(!regexFilterKey.test(currentTile.key));
    }

    if (currentTile.key==='' || !regexFilterKey.test(currentTile.key) ||keyAlreadyExists) {
      dispatch(setKeyValue(true));
    }else  dispatch(setKeyValue(false));
  

    if (currentTile.additionalInfo) {
      handleFilterPropertyChange(
        "link",
        true
      )
    }
    else
      handleFilterPropertyChange(
        "link",
        false
      )
    if (currentTile.shouldStreamResponse) {
      setStreamResponse(true)
    }else setStreamResponse(false)
  }, [currentTile]);

  const { t } = useTranslation();

 
  useEffect(
    () => (document.title = `${params.tile?.toUpperCase()}: UI Settings`),
    []
  );

  async function handleSave() {

    setIsLoading(true);
    if (isNewTile) {
      await createNewFilter();
      setIsNewTile(false);
    } else {
      await saveFilterChanges();
    }
    setIsLoading(false);
  }

  function handleOnStreamChange() {
    handleFilterPropertyChange("shouldStreamResponse", !isStream);
    setStreamResponse((prevState) => !prevState);
    dispatch(setChanges(true));

  }

  return !isLoading && currentTile && tiles ? (
    <PageWrapper>
      <ContentWrapper>
        <QueriesLangSwitch customHandleSave={handleSave} />
        <ColumsWrapper>
          <ColumnDiv>
            <TextDiv>
              <FormWrapper>
                <InputWrapper>
                  <Label opacity={isNewTile ? 1 : 0.4}>
                    {t("uiSettingsPage.tileKey")}
                  </Label>
                  <StyledInput
                    opacity={isNewTile ? 1 : 0.4}
                    disabled={!isNewTile}
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.key}
                    onChange={(e) => {
                      handleFilterPropertyChange("key", e.target.value);
                      dispatch(setChanges(true));
                    }}
                  />
                  {keyAlreadyExists && (
                    <PossibleValue color="red">
                      {t("uiSettingsPage.keyAlreadyExists")}
                    </PossibleValue>
                  )}
                  {invalidKey && (
                    <PossibleValue color="red">
                      {t("uiSettingsPage.possibleKeyValue")}
                    </PossibleValue>
                  )}
                </InputWrapper>
                <InputWrapper>
                  <Label>{t("uiSettingsPage.tileName")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.displayName}
                    onChange={(e) =>{
                      handleFilterPropertyChange("displayName", e.target.value)
                      dispatch(setChanges(true));
                    }
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>{t("uiSettingsPage.buttonText")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.buttonText}
                    onChange={(e) =>{
                      handleFilterPropertyChange("buttonText", e.target.value)
                      dispatch(setChanges(true));
                    }
                    }
                    rows={2}
                  />
                  <PossibleValue>
                    {t("uiSettingsPage.possibleValue")}
                  </PossibleValue>
                </InputWrapper>

                <InputWrapper>
                  <Label>{t("uiSettingsPage.additionalInfoCondition")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.additionalInfo || additionalInfo}
                    onChange={(e) => {
                      setAdditionalInfo(e.target.value);
                      handleFilterPropertyChange(
                        "additionalInfo",
                        e.target.value
                      );
                      handleFilterPropertyChange(
                        "link", true
                      );
                      dispatch(setChanges(true));

                    }}
                  />
                </InputWrapper>
                <LinkLabel>
                  {t("uiSettingsPage.streaming")}
                </LinkLabel>
                <Wrapper>
                  <SwitchWrapper>
                    <RoundedSwitch
                      checked={isStream}
                      onChange={handleOnStreamChange}
                      variant="secondary"
                    />
                  </SwitchWrapper>
                  <LabelWrapper>
                    <span className={`${!isStream ? "active" : ""}`}>OFF</span>
                    <span className={`${isStream ? "active" : ""}`}>ON</span>
                  </LabelWrapper>
                </Wrapper>

              </FormWrapper>
            </TextDiv>
          </ColumnDiv>
          <Divider />
          <ColumnDiv>
            <Spacing />
            <TextDiv>
              <FormWrapper>
                <SettingsAccordion title={t("uiSettingsPage.tileDescription")}>
                  <StyledTextArea
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.description}
                    rows={4}
                    onChange={(e) =>
                    {
                      handleFilterPropertyChange("description", e.target.value)
                      setTileDescription(e.target.value)
                      dispatch(setChanges(true));

                    }
                    }
                    maxLength={72}
                  />
                </SettingsAccordion>
                {(tileDescription.length >=72||currentTile.description>=72) && (
                  <PossibleValue color="red">
                  {t("uiSettingsPage.characterLimit")}
                </PossibleValue>
                )}
                <InputWrapper>
                  <Label>{t("uiSettingsPage.tipsTips")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.tileTipsTips}
                    onChange={(e) =>{
                      handleFilterPropertyChange("tileTipsTips", e.target.value)
                      dispatch(setChanges(true));
                    }
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>{t("uiSettingsPage.tipsUser")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.tileTipsUser}
                    onChange={(e) =>{
                      handleFilterPropertyChange("tileTipsUser", e.target.value)
                      dispatch(setChanges(true));
                    }
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>{t("uiSettingsPage.tipsGenai")}</Label>
                  <StyledInput
                    placeholder={t("uiSettingsPage.description")}
                    value={currentTile.tileTipsGenai}
                    onChange={(e) =>{
                      handleFilterPropertyChange(
                        "tileTipsGenai",
                        e.target.value
                      )
                      dispatch(setChanges(true));

                    }
                  }
                  />
                </InputWrapper>
              </FormWrapper>
            </TextDiv>
          </ColumnDiv>
        </ColumsWrapper>
        <SaveButtonWrapper>
          {/* <SaveButton style={{ width: "auto" }} disabled={!changes} /> */}
          <Button
            variant="secondary"
            type="submit"
            onClick={() => handleSave()}
            disabled={
              !changes ||
              keyAlreadyExists ||
              currentTile.key.length === 0 ||
              !regexFilterKey.test(currentTile.key)
            }
          >
            {t("layout.Save")}
          </Button>
        </SaveButtonWrapper>
      </ContentWrapper>
    </PageWrapper>
  ) : (
    <Loader />
  );
}

const Spacing = styled.div`
  height: 7px;
`;
const PossibleValue = styled.div`
  font-size: 14px;
  color: ${(props) => props.color};
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  gap: 6px;
`;
const Wrapper = styled.div`
  width: 11rem;
`;
const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 3rem;
  font-size: var(--font-size-medium);
  & .active {
    font-weight: 900;
  }
`;
const LinkLabel = styled.label`
  margin-top: 1rem;
  font-weight: var(--font-weight-medium);
  opacity: ${(props) => props.opacity};
  color: ${(props) => props.color || null};
`;
