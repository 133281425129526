import { createSlice } from "@reduxjs/toolkit";
import {
  addFilter,
  editFilter,
  removeFilter,
  setChildFilters,
  addParentFilter,
  editParentFilter,
  removeParentFilter,
} from "./filtersSlice";
import { addLink, editLink, removeLink, setLinks } from "./menuSlice";
import {
  setImage,
  setMain,
  setSub,
  setDisclaimerUrl,
  setWelcomeDisclaimer,
} from "./welcomeSlice";
import { setAgentCheck, setBotCheck } from "./chatModeSlice";
// import { setFilterProperty } from "./uisettingsSlice";
import { setSetting } from "./backendSettingsSlice";

export const changeSlice = createSlice({
  name: "change",
  initialState: {
    changes: false,
    keyValue:false
  },
  reducers: {
    setChanges: (state, action) => {
      state.changes = action.payload;
    },
    setKeyValue: (state, action) => {
      state.keyValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    const actions = [
      setChildFilters,
      addFilter,
      addParentFilter,
      editParentFilter,
      removeFilter,
      removeParentFilter,
      editFilter,
      setLinks,
      addLink,
      removeLink,
      editLink,
      setMain,
      setSub,
      setImage,
      setAgentCheck,
      setBotCheck,
      setWelcomeDisclaimer,
      setDisclaimerUrl,
      // setFilterProperty,
      setSetting,
    ];

    actions.forEach((action) => {
      builder.addCase(action, (state) => {
        state.changes = true;
      });
    });
  },
});

export const { setChanges, setStatus,setKeyValue } = changeSlice.actions;
export default changeSlice.reducer;
